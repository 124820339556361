<template>
  <div
    v-if="action"
    class="h-full flex flex-col action-editor"
  >
    <div>
      <h2 class="font-bold mb-4">Edit</h2>
      <div
        class="close"
        @click="$emit('cancel')"
      />
    </div>

    <div class="select-container mb-6 mt-2">
      <select
        v-model="selectedTemplateId"
        name="template"
      >
        <option :value="null">Select a template</option>
        <option
          v-for="t in templates"
          :key="t.id"
          :value="t.id"
        >
          {{ t.name }}
        </option>
      </select>
    </div>

    <div class="border-t border-grey-e3 -mx-8 px-8 py-4 text-grey-semi-light">
      <span class="mr-2">To:</span>
      <span>{{ email.to }}</span>
    </div>

    <div class="border-t border-grey-e3 -mx-8 px-8 py-4 text-grey-semi-light">
      <span class="mr-2">From:</span>
      <span>{{ email.sender }} &lt;{{ email.from }}&gt;</span>
    </div>

    <div class="border-t border-grey-e3 -mx-8 px-8">
      <input
        class="w-full input-modal"
        type="text"
        v-model="email.subject"
        placeholder="Subject"
      >
    </div>

    <div class="-mx-8 flex-grow">
      <v-ckeditor-token v-model="email.content" />
    </div>

    <div
      v-if="type === 'proposal'"
      class="font-bold text-grey-darker flex items-center border-b border-grey-e3 pt-4 -mx-8 px-8 py-4">
      <icon
        class="mr-2"
        icon="invoice-alt"
      />
      <div
        v-if="proposal"
      >
        Proposal: {{ proposal ? proposal.name : 'None' }}
        <router-link
          class="ml-1 font-normal text-green border-b border-green"
          :to="`/projects/${action.project_id}/proposal/${proposal.id}`"
        >
          Edit
        </router-link>
      </div>
      <div
        v-else
      >
        Proposal: Loading...
      </div>
    </div>

    <div
      v-if="type === 'questionnaire'"
      class="font-bold text-grey-darker flex items-center border-b border-grey-e3 pt-4 -mx-8 px-8 py-4">
      <icon
        class="mr-2"
        icon="invoice-alt"
      />
      <div
        v-if="questionnaire"
      >
        Questionnaire: {{ questionnaire ? questionnaire.name : 'None' }}
        <router-link
          class="ml-1 font-normal text-green border-b border-green"
          :to="`/projects/${action.project_id}/questionnaire/${questionnaire.id}`"
        >
          Edit
        </router-link>
      </div>
      <div
        v-else
      >
        Questionnaire: Loading...
      </div>
    </div>


    <div class="mt-4">
      <button
        v-if="!sending"
        class="grey-btn mr-4"
        @click="$emit('cancel')"
      >
        Back
      </button>
      <button
        v-if="!sending"
        :disabled="sending"
        :class="{
          'green-btn': !sending,
          'grey-btn': sending,
          'mr-4': true
        }"
        @click="send"
      >
        <template v-if="sending">Sending</template>
        <template v-else>Send</template>
      </button>
      <button
        v-if="!sending"
        class="delete-btn"
        @click="$emit('delete')"
      >
        Delete
      </button>
      <loader v-if="sending" />
    </div>
  </div>
</template>

<script>
import VCkeditorToken from '../VCkeditorToken/VCkeditorToken'
import auth from '../../auth'
import { getMailTemplates } from '../../api/TemplateMail'
import { createProposalFromTemplate } from '../../api/Proposal'
import Icon from '../Icon'
import { createQuestionnaireFromTemplate } from '../../api/Questionnaire'
import { sendMail } from '../../api/Mail'
import Loader from '../Loader'

export default {
  name: 'ActionEditor',
  components: { Loader, Icon, VCkeditorToken },
  emits: ['sent', 'cancel'],
  props: {
    project: {
      type: Object,
      required: false,
      default: null
    },
    action: {
      type: Object,
      required: false,
      default: null
    },
    to: {
      type: String,
      required: false,
      default: ''
    },
    type: {
      type: String,
      required: false,
      default: 'project'
    },
    template: {
      type: Object,
      required: false,
      default: null
    }
  },
  data() {
    const user = auth.user()

    const locale = this.project ? this.project.locale : 'en'
    const subject = this.template ? this.template.subject : ''
    const content = this.template ? this.template.content : ''

    return {
      user: user,
      selectedTemplate: this.template ? this.template : null,
      selectedTemplateId: this.template ? this.template.id : null,
      selectedQuestionnaire: this.type === 'questionnaire' ? this.action.payload.actionable : null,
      proposal: null,
      questionnaire: null,
      templates: [],
      sending: false,
      email: {
        to: this.to,
        from: user.companySettings.senderEmail,
        sender: user.companySettings.senderName,
        subject: typeof subject === 'string' ? subject : subject[locale],
        content: this.getEmailContent(content)
      }
    }
  },
  watch: {
    selectedTemplateId(templateId) {
      const template = this.templates.find(t => t.id === templateId)
      const {subject, content} = template
      const locale = this.project ? this.project.locale : 'en'

      this.selectedTemplate = template
      this.email.subject = typeof subject === 'string' ? subject : subject[locale]
      this.email.content = this.getEmailContent(content)
    }
  },
  methods: {
    getEmailContent(content) {
      const user = auth.user()
      const locale = this.project ? this.project.locale : 'en'
      const signature = (!user.me.email_signature) ? '' : user.me.email_signature
      const result = typeof content === 'string' ? content : content[locale]

      // If no result, ask if the user wants to add a translation
      if (!result) {
        const shouldRedirect = this.$alert.confirm({
          title: 'This template is not translated in this language',
          text: '',
          buttons: ['Cancel', 'Add translation']
        }).then((result) => {
          if (result) {
            return this.$router.push(`/templates/mails/${this.selectedTemplate.id}`)
          }
        })

        return ''
      } else {
        // Check if the project requires a date
        if (result.includes("@projectDate") && !this.project.date) {
          this.$toasted.error('This email uses @projectDate, add a project date first.')
          this.$emit('cancel')

          return
        }

        return result + signature
      }
    },
    async loadTemplates() {
      const res = this.handleErrors(await getMailTemplates(this.user.company.id))

      if (!res) {
        return
      }

      this.templates = res.data.mails
    },
    async createProposal() {
      const res = this.handleErrors(await createProposalFromTemplate(
        this.user.company.id,
        this.action.payload.actionable_id,
        this.action.id
      ))

      if (res) {
        this.proposal = res.data
      }
    },
    async createQuestionnaire() {
      const res = this.handleErrors(await createQuestionnaireFromTemplate(
        this.user.company.id,
        this.action.payload.actionable_id,
        this.action.id
      ))

      if (res) {
        this.questionnaire = res.data
      }
    },
    async send() {
      this.sending = true

      if (this.email.content.includes("@projectDate") && !this.project.date) {
        this.$toasted.error('Email not sent. This project requires a project date.')
        this.$emit('cancel')

        return
      }

      let itemId = this.action.project_id
      if (this.type === 'proposal') itemId = this.proposal.id
      if (this.type === 'questionnaire') itemId = this.questionnaire.id

      const res = this.handleErrors(await sendMail({
        to: this.email.to,
        from: this.email.from,
        senderName: this.email.sender,
        subject: this.email.subject,
        content: this.email.content,
        type: this.type,
        itemId,
        workflowJobId: this.action.id
      }))

      if (res) {
        this.$emit('sent')
      }

      this.sending = false
    }
  },
  mounted() {
    this.loadTemplates()

    if (this.type === 'proposal') {
      this.createProposal()
    }

    if (this.type === 'questionnaire') {
      this.createQuestionnaire()
    }
  }
}
</script>

<style lang="scss">
.action-editor .ck{
  .ck-toolbar{
    border: none;
    border-top: 1px solid #e2e2e2;
    padding:5px 30px!important;
  }
  &.ck-editor__editable{
    border-radius: 0!important;
    height: 100%;
    padding: 10px 40px!important;
    border: none!important;
    border-top: 1px solid #e2e2e2!important;
    border-bottom: 1px solid #e2e2e2!important;
  }
  &.ck-editor{
    height: 100%;
  }
  .ck-editor, .ck-editor__main{
    height: calc(100% - 50px);
  }
  .ck-editor__main > .ck-editor__editable:not(.ck-focused) {
    border-top: 1px solid #e2e2e2!important;
  }
  .ck-content{
    min-height: 100%;
  }
}
</style>
