var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.action.automated)?_c('button',{staticClass:"bg-grey-f9 py-2 px-4 rounded inline-flex items-center action-item action-item--automated",class:{
    'text-grey-darker': _vm.action.status !== 'completed',
    'text-grey-semi-light line-through': _vm.action.status === 'completed'
  },attrs:{"disabled":_vm.action.status === 'completed'},on:{"click":function($event){return _vm.$emit('click')}}},[_c('icon',{staticClass:"mr-2",attrs:{"icon":"refresh"}}),_c('span',{staticClass:"action-name"},[_vm._v(" "+_vm._s(_vm.action.name)+" ")])],1):_c('button',{staticClass:"py-2 px-4 rounded action-item action-name",class:{
    'bg-green text-white font-bold': _vm.action.status !== 'completed',
    'bg-grey-f9 grey-semi-light line-through': _vm.action.status === 'completed'
  },attrs:{"disabled":_vm.action.status === 'completed'},on:{"click":function($event){return _vm.$emit('click')}}},[_vm._v(" "+_vm._s(_vm.action.name)+" ")])
}
var staticRenderFns = []

export { render, staticRenderFns }