<template>
  <div class="project">
    <vue-headful
      :title="`${metaTitle} | Octoa`"
    />

    <v-video-modal
      name="detail"
      title="Project detail"
      description="A video to help you get started."
      step="project-detail"
      thumbnail="project-detail.png"
      embed="IlAqxeSDH1Y"
      :show.sync="isShowProjectDetailModal"
      @close="closeProjectDetailModal"
    />

    <modal
      name="paymentModal"
      class="modal overflow-visible"
      width="90%"
      height="280"
      :max-width="600"
      :adaptive="true"
    >
      <div class="flex justify-between">
        <div class="title">
          Add payment
        </div>
        <div
          class="close "
          @click="$modal.hide('paymentModal')"
        />
      </div>

      <div class="flex mt-5 items-center">
        <div class="w-4/12 md:w-32">
          <div class="pr-2">
            Payment date:
          </div>
        </div>
        <div class="w-8/12 md:w-4/12">
          <v-date-picker
            v-model="addPayment.date"
            class="datepicker"
            name="payment_date"
            :popover="{ visibility: 'click' }"
            :input-props="{
              placeholder: &quot;Date of payment&quot;,
            }"
            :masks="{L: 'WWW, MMM D, YYYY'}"
          >
            <template v-slot="{ inputValue, inputEvents }">
              <input
                type="text"
                :value="inputValue"
                v-on="inputEvents"
              >
            </template>
          </v-date-picker>
        </div>
      </div>

      <div class="flex flex-wrap mt-3 items-center">
        <div class="w-4/12 md:w-32">
          <div class="pr-2">
            Amount ({{ currency.sign }})
          </div>
        </div>
        <div class="w-8/12 md:w-4/12">
          <vue-numeric
            v-model="addPayment.amount"
            :currency-symbol-position="(currency.sign_after_price) ? 'suffix' : 'prefix'"
            :precision="2"
            :currency="currency.sign"
            :minus="false"
            :decimal-separator="currency.decimal_separator"
            :thousand-separator="currency.thousand_separator"
            :min="0"
          />
        </div>
        <div class="w-full md:w-auto mt-5 md:mt-0 md:ml-4">
          Remaining amount:
          <vue-numeric
            v-model="remainingPaymentAmount"
            :currency-symbol-position="(currency.sign_after_price) ? 'suffix' : 'prefix'"
            :precision="2"
            :currency="currency.sign"
            read-only
            :minus="false"
            :decimal-separator="currency.decimal_separator"
            :thousand-separator="currency.thousand_separator"
            :min="0"
          />
        </div>
      </div>

      <div class="flex justify-center md:justify-end flex-wrap mt-8 items-center">
        <div class="w-1/2 text-center md:text-right md:w-3/12">
          <v-button
            class="green-btn"
            @click.native="recordPayment"
          >
            Add payment
          </v-button>
        </div>
        <div class="w-1/2 text-center md:text-right md:w-1/12 md:ml-5">
          <div
            class="underline text-green cursor-pointer"
            @click="$modal.hide('paymentModal')"
          >
            Cancel
          </div>
        </div>
      </div>
    </modal>

    <modal
      name="editProject"
      class="modal overflow-visible"
      width="100%"
      :max-width="700"
      height="auto"
      :adaptive="true"
    >
      <div class="modal-content-wrapper">
        <div class="flex px-2 justify-between">
          <div class="title">
            Edit project details
          </div>
          <div
            class="close "
            @click="$modal.hide('editProject')"
          />
        </div>

        <div class="flex px-2 mt-2">
          <div class="input-group w-full">
            <label>Title</label>
            <input
              v-model="editProject.name"
              v-validate="{ required: true }"
              type="text"
              name="projectName"
              class="w-full"
            >
            <span
              v-if="errors.has('projectName')"
              class="error"
            >A project name is required.</span>
          </div>
        </div>

        <div class="flex flex-wrap px-2 -mx-2 mt-2">
          <div class="input-group w-full md:w-1/2 px-2">
            <label>Status</label>
            <div class="select-container w-full">
              <select
                v-if="workflow && workflow.phases"
                v-model="editProject.state"
              >
                <option
                  v-for="phase in workflow.phases"
                  :key="phase.slug"
                  :value="phase.slug"
                >
                  {{ phase.name }}
                </option>
              </select>
            </div>
          </div>
          <div class="input-group w-full md:w-1/2 px-2">
            <div class="flex justify-between items-center">
              <label>Project date</label>
              <div class="flex items-center">
                <v-checkbox
                  id="multi-days"
                  v-model="multiDays"
                  name="multi-days"
                  :checked="multiDays"
                  label="Multi-day"
                />
              </div>
            </div>
            <v-date-picker
              v-if="!multiDays"
              v-model="editProject.date"
              v-validate="{ required: true }"
              class="datepicker"
              name="projectDate"
              :popover="{ visibility: 'click' }"
              :masks="{L: 'WWW, MMM D, YYYY'}"
              :input-props="{
                readonly: true
              }"
            >
              <template v-slot="{ inputValue, inputEvents }">
                <input
                  type="text"
                  :value="inputValue"
                  v-on="inputEvents"
                >
              </template>
            </v-date-picker>
            <div
              v-if="multiDays"
              class="flex"
            >
              <v-date-picker
                v-model="editProject.date"
                class="datepicker mr-2"
                :popover="{ visibility: 'click' }"
                :masks="{L: 'WWW, MMM D, YYYY'}"
              >
                <template v-slot="{ inputValue, inputEvents }">
                  <input
                    type="text"
                    placeholder="Start date"
                    :value="inputValue"
                    v-on="inputEvents"
                  >
                </template>
              </v-date-picker>
              <v-date-picker
                v-model="editProject.endDate"
                class="datepicker"
                :popover="{ visibility: 'click' }"
                :masks="{L: 'WWW, MMM D, YYYY'}"
              >
                <template v-slot="{ inputValue, inputEvents }">
                  <input
                    type="text"
                    placeholder="End date"
                    :value="inputValue"
                    v-on="inputEvents"
                  >
                </template>
              </v-date-picker>
            </div>
            <span
              v-if="projectDateError"
              class="error"
            >
              {{ projectDateError }}
            </span>
          </div>
        </div>

        <div class="flex px-2 mt-2">
          <div class="input-group w-full">
            <label>Location</label>
            <input
              v-model="editProject.location"
              type="text"
              name="location"
              class="w-full"
            >
          </div>
        </div>

        <div class="flex flex-wrap px-2 -mx-2 mt-2">
          <div class="w-full md:w-1/2">
            <div class="input-group px-2 mt-1 selector">
              <label>Tags (comma separated)</label>
              <vue-tags-input
                v-model="tag"
                placeholder=""
                :tags="tags"
                :autocomplete-items="autocompleteTagItems"
                :add-on-key="[188]"
                @tags-changed="updateTags"
              />
            </div>
          </div>
          <div class="w-full md:w-1/2">
            <div
              class="input-group px-2"
            >
              <label>Language <v-help-tooltip
                :options="languageContent"
              /></label>
              <div class="select-container w-full">
                <select v-model="editProject.language">
                  <option
                    v-for="language in activeLanguages"
                    :key="language.code"
                    :value="language.code"
                  >
                    {{ language.label }}
                  </option>
                </select>
              </div>
            </div>
          </div>
        </div>
        <div class="flex flex-wrap px-2 -mx-2 mt-2">
          <div class="w-full md:w-1/2 px-2">
            <div
              class="input-group block"
            >
              <label>Choose workflow <v-help-tooltip
                class="absolute"
                :options="{content: ''}"
              /></label>
              <div class="select-container w-full">
                <select
                  v-model="editProject.workflow_id"
                  v-validate="{ required: true, is_not: null }"
                  name="workflow_id"
                >
                  <option :value="null">Select a workflow</option>
                  <option
                    v-for="workflow in workflows"
                    :key="workflow.id"
                    :value="workflow.id"
                  >
                    {{ workflow.name }}
                  </option>
                </select>
              </div>
              <span
                v-if="errors.has('workflow_id')"
                class="error"
              >A workflow is required.</span>
            </div>
          </div>
          <div class="w-full md:w-1/2">
            <div
              v-show="multiCurrencies == 1"
              class="input-group px-2"
            >
              <label>Currency</label>
              <div class="select-container w-full">
                <select v-model="editProject.currency_id">
                  <option
                    v-for="currency in currencies"
                    :key="currency.id"
                    :value="currency.id"
                  >
                    {{ currency.name }}
                  </option>
                </select>
              </div>
            </div>
          </div>
          <div
            class="w-full text-right mb-10"
            :class="{ 'mt-5 pr-2': multiCurrencies == 1 }"
          >
            <div
              class="inline-block cursor-pointer underline text-green mr-5"
              @click="deleteProject()"
            >
              Delete
            </div>
            <div
              v-if="!project.is_archived"
              class="inline-block cursor-pointer underline text-green mr-5"
              @click="archive()"
            >
              Archive project
            </div>
            <div
              v-if="project.is_archived"
              class="inline-block cursor-pointer underline text-green mr-5"
              @click="unarchive()"
            >
              Unarchive project
            </div>
            <button
              class="green-btn "
              @click="saveProject"
            >
              Save
            </button>
          </div>
        </div>
      </div>
    </modal>

    <project-contact-slide-in
      v-if="project.contact"
      :show.sync="showContactSlide"
      :contact.sync="project.contact"
      @close="hideContactSlideIn"
      @saved="savedContactSlideIn"
    />

    <project-email-slide-in
      :project.sync="project"
      :mode.sync="emailEditMode"
      :show.sync="showEmailSlide"
      :email.sync="email"
      @close="hideEmailSlideIn"
      @saved="savedEmailSlideIn"
    />

    <div class="sub-header">
      <div class="w-full max-w-2xl mx-auto">
        <div class="flex flex-wrap flex-grow justify-between items-center  px-4">
          <div class="w-full md:w-1/2 lg:w-2/3">
            <div
              v-show="isLoading"
              class="flex items-center"
            >
              <div class="w-1/3">
                <v-content-loader
                  :width="150"
                  :height="15"
                  primary-color="#494c5d"
                  secondary-color="#6b6e7d"
                  :speed="1"
                >
                  <rect
                    x="0"
                    y="0"
                    rx="2"
                    ry="2"
                    width="150"
                    height="15"
                  />
                </v-content-loader>
              </div>
              <div class="w-16 ml-4">
                <v-content-loader
                  :width="50"
                  :height="15"
                  primary-color="#494c5d"
                  secondary-color="#6b6e7d"
                  :speed="1"
                >
                  <rect
                    x="0"
                    y="0"
                    rx="2"
                    ry="2"
                    width="50"
                    height="15"
                  />
                </v-content-loader>
              </div>
              <div class="w-10 ml-4">
                <v-content-loader
                  :width="26"
                  :height="13"
                  primary-color="#494c5d"
                  secondary-color="#6b6e7d"
                  :speed="1"
                >
                  <rect
                    x="0"
                    y="0"
                    rx="2"
                    ry="2"
                    width="26"
                    height="13"
                  />
                </v-content-loader>
              </div>
            </div>
            <div
              v-show="!isLoading"
              class="flex items-center"
            >
              <div>
                <h1 class="text-white inline-block">
                  {{ project.name }}
                </h1>
              </div>
              <div class="static-tag ml-4">
                {{ project.locale | viewLocale }}
              </div>
              <div class="static-tag ml-4">
                {{ currency.code }}
              </div>
            </div>
            <div
              v-if="isLoading"
              class="flex items-center"
            >
              <div class="w-48 mt-2">
                <v-content-loader
                  :width="150"
                  :height="8"
                  primary-color="#494c5d"
                  secondary-color="#6b6e7d"
                  :speed="1"
                >
                  <rect
                    x="0"
                    y="0"
                    rx="2"
                    ry="2"
                    width="150"
                    height="8"
                  />
                </v-content-loader>
              </div>
            </div>
            <div
              v-if="!isLoading && project.date"
              class="mt-2 text-blue-washed-grey"
            >
              Date: {{ formattedDate }} <span v-if="project.location">@ {{ project.location }}</span>
              <span
                v-if="project.is_double_date_warning"
                v-tooltip="{ ...tooltip, content: 'You have a double booking on this date.' }"
                class="ml-3 align-middle"
              >
                <img src="@/assets/img/icons/ico-alert-orange.svg">
              </span>
            </div>
            <div class="block md:hidden my-3 text-white">
              Phase: <span class="font-bold capitalize">{{ project.state }}</span>
            </div>
          </div>
          <div class="w-full flex-row-reverse md:flex-row md:w-1/2 lg:w-1/3 justify-end flex mt-2 md:mt-0 mb-5 md:mb-0">
            <div>
              <v-tutorial-button
                mode="dark"
                @click="showProjectDetailModal"
              />
            </div>
            <div class="mx-5">
              <v-client-portal-button
                mode="dark"
                @click="showClientPortal"
              />
            </div>
            <div
              class="green-btn"
              @click="showEditProjectModal"
            >
              Edit <span class="hidden md:inline">project</span>
            </div>
          </div>
        </div>

        <div
          v-if="workflow && workflow.phases"
          class="progress hidden md:flex mt-4 flex-grow items-center mb-6 flex-wrap"
        >
          <div
            v-for="(phase, index) in workflow.phases"
            class="phase state hover:text-white mt-4"
            :class="{
              [phase.slug]: true,
              'active': phase.slug === project.state,
              'semi-active': workflow.phases.findIndex(p => p.slug === project.state) > index,
            }"
            @click="updateProjectState(phase.slug)"
          >
            <div>
              {{ phase.name }}
            </div>
            <div
              class="mb-2 flex-0"
            >
              <action-progress
                :class="{
                  'invisible': phase.slug !== project.state,
                }"
                :actions="project.phase_actions"
              />
            </div>
            <div class="flex">
              <div class="phase-line" />
              <div class="phase-dot" />
              <div class="phase-line" />
            </div>
          </div>
        </div>

        <div class="flex md:hidden tab px-4  justify-between items-center">
          <div
            :class="{'active' : view == 'workflow'}"
            @click="switchView('workflow')"
          >
            Workflow
          </div>
          <div
            :class="{'active' : view == 'activities'}"
            @click="switchView('activities')"
          >
            Activities
          </div>
          <div
            :class="{'active' : view == 'files'}"
            @click="switchView('files')"
          >
            Files
          </div>
          <div
            :class="{'active' : view == 'tasks'}"
            @click="switchView('tasks')"
          >
            Tasks
          </div>
          <div
            :class="{'active' : view == 'contact'}"
            @click="switchView('contact')"
          >
            Contact
          </div>
          <div
            :class="{'active' : view == 'notes'}"
            @click="switchView('notes')"
          >
            Notes
          </div>
        </div>
      </div>
    </div>

    <div
      v-if="isLoading"
      class="text-center"
    >
      <img
        src="@/assets/img/icons/loader.svg"
        width="50"
        class="mt-32 text-center"
      >
    </div>

    <!-- Overview -->
    <div
      v-else
      class="bg-grey-light h-full pb-24 pt-3 md:pt-8 mt-2 px-4"
    >
      <div class="flex flex-wrap flex-col-reverse md:flex-row max-w-2xl md:px-4 mx-auto">
        <div class="w-full md:w-3/5 ">
          <div
            v-show="!isMobile || (isMobile && view === 'workflow')"
            class="mb-16"
            :class="{
              'mr-6': !isMobile
            }"
          >
            <h3 v-if="!isMobile">Workflow</h3>
            <project-workflow-summary
              :hide-title="isMobile"
              :project="project"
              @update="initData"
            />
          </div>
          <div
            v-show="!isMobile || (isMobile && view == 'activities')"
          >
            <div class="flex justify-between md:mr-6 mb-6 mt-6 md:mt-0">
              <h3 class="hidden md:block">
                Activities
              </h3>
              <div class="flex text-xs text-grey-darker items-center">
                <div
                  class="mr-8"
                  :class="{
                    'font-bold' : activityViewFilter === 'all',
                    'underline cursor-pointer': activityViewFilter !== 'all'
                  }"
                  @click="activityFilter('all')"
                >
                  All
                </div>
                <div
                  class="mr-8"
                  :class="{
                    'font-bold' : activityViewFilter === 'project',
                    'underline cursor-pointer': activityViewFilter !== 'project'
                  }"
                  @click="activityFilter('project')"
                >
                  Files
                </div>
                <div
                  :class="{
                    'font-bold' : activityViewFilter === 'email',
                    'underline cursor-pointer': activityViewFilter !== 'email'
                  }"
                  @click="activityFilter('email')"
                >
                  Emails
                </div>
              </div>
            </div>

            <div>
              <!-- No activities -->
              <div
                v-if="items.length == 0"
                class="no-activities"
              >
                <div v-if="!isLoading">
                  Activities will <br>
                  appear here to keep track <br>
                  on your active project. <br>
                </div>
              </div>
              <div v-else>
                <div class="activities bg-white border border-grey-lighter rounded p-10 pb-4 md:mr-6">
                  <div
                    v-if="totalEmails == 0 && activityViewFilter == 'email'"
                    class="no-activities"
                  >
                    Any draft and sent emails <br>
                    related to this project will <br>
                    appear here. <br>
                  </div>

                  <!-- Items -->
                  <div
                    v-for="(item, index) in items"
                    :key="index"
                  >
                    <div
                      v-show="(activityViewFilter == 'all') || (item.type == 'email' && activityViewFilter == 'email') || (item.type != 'email' && activityViewFilter == 'project')"
                      class="activity flex flex-grow mb-4 text-xs"
                    >
                      <div class="flex flex-col items-center mr-4">
                        <icon
                          v-if="item.type === 'questionnaire'"
                          icon="questionnaire"
                          class="w-4 h-4"
                        />
                        <icon
                          v-if="item.type === 'proposal'"
                          icon="proposal-alt"
                          class="w-4 h-4"
                        />
                        <icon
                          v-if="item.type === 'invoice'"
                          icon="invoice-black"
                          class="w-4 h-4"
                        />
                        <icon
                          v-if="item.type === 'email'"
                          icon="email-alt"
                          class="w-4 h-4"
                        />
                        <icon
                          v-if="item.type === 'lead'"
                          icon="leadform-alt"
                          class="w-4 h-4"
                        />

                        <div
                          v-if="index !== (items.length - 1)"
                          class="border-l border-grey-lighter flex-1 w-px mt-4"
                        />
                      </div>
                      <div
                        v-if="item.type == 'lead'"
                        class="w-full mb-8"
                      >
                        <div class="px-6">
                          <strong>Lead form: Inquiry</strong>
                          <div class="mt-3 text-grey-semi-light">
                            Filled in at {{ item.created_at | humanDateWithTimeStamp(timezone) }}
                          </div>
                          <div
                            class="bg-grey-f9 p-4 rounded mt-3"
                          >
                            <div
                              v-for="answer in item.answers"
                              :key="answer.id"
                              class="mb-4"
                            >
                              <div class="font-bold">
                                {{ answer.label }}
                              </div>
                              <div class="mt-2">
                                <div v-if="answer.type == 'datepicker' || answer.type == 'textarea'">
                                  <span v-if="answer.type == 'datepicker'">{{ answer.date | humanDateNoConvert }}</span>
                                  <pre v-if="answer.type == 'textarea'">{{ answer.value }}</pre>
                                </div>
                                <span v-else>{{ answer.value }}</span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div
                        v-else
                        class="w-full mb-8"
                      >
                        <div
                          :class="{'active': item.type != 'lead' && item.state != 'answered' && item.state != 'accepted' && item.state != 'paid' }"
                          class=""
                        >
                          <div class="px-6">
                            <div class="flex justify-between">
                              <div>
                                <span v-if="item.type != 'email'">
                                  <span v-if="item.type == 'proposal'"><strong>Proposal:</strong><span v-if="item.subject"> {{ item.subject }}</span><span v-if="item.name && !item.subject"> {{ item.name }}</span><span v-if="!item.subject && !item.name"> {{ item.reference_number }}</span></span>
                                  <span v-if="item.type == 'questionnaire'"><strong>Questionnaire:</strong> {{ item.name }}</span>
                                  <span v-if="item.type == 'invoice'"><strong>Invoice:</strong><span v-if="item.subject"> {{ item.subject }}</span><span v-if="item.name && !item.subject"> {{ item.name }}</span><span v-if="!item.subject && !item.name && item.invoice_number"> {{ item.invoice_number }}</span></span>
                                </span>
                                <span v-else>
                                  <strong>Email:</strong> {{ item.subject }}
                                </span>
                              </div>
                              <div class="text-xs text-blue">
                                <template v-if="item.type != 'email'">
                                  <span v-if="item.state == 'sent' && !item.viewed_at">Unopened</span>
                                  <span v-if="item.viewed_at">Opened {{ item.viewed_at | timeFromNow }} ago</span>
                                </template>
                                <template v-else>
                                  <span v-if="item.state == 'sent' && item.number_of_times_read == 0">Unopened</span>
                                  <span v-if="item.state == 'sent' && item.number_of_times_read > 0">Last opened {{ item.updated_at | timeFromNow }} ago</span>
                                </template>
                              </div>
                            </div>
                            <div v-if="item.type == 'email'">
                              <div
                                v-if="item.state == 'draft'"
                                class="mt-2"
                              >
                                <span class="text-grey-semi-light">Created on {{ item.created_at | humanDateWithTimeStamp(timezone) }}</span>
                              </div>
                              <div
                                v-if="item.state == 'sent'"
                                class="mt-2"
                              >
                                <span class="text-grey-semi-light">Sent on {{ item.sent_at | humanDateWithTimeStamp(timezone) }}</span>
                              </div>
                            </div>
                            <div v-if="item.type == 'questionnaire'">
                              <div
                                v-if="item.state == 'draft'"
                                class="mt-2"
                              >
                                <span class="text-grey-semi-light">Created on {{ item.created_at | humanDateWithTimeStamp(timezone) }}</span>
                              </div>
                              <div
                                v-if="item.state == 'sent'"
                                class="mt-2"
                              >
                                <span class="text-grey-semi-light">Sent on {{ item.sent_at | humanDateWithTimeStamp(timezone) }}</span>
                              </div>
                              <div
                                v-if="item.state == 'answered'"
                                class="mt-2"
                              >
                                <span class="text-grey-semi-light">Answered on {{ item.answered_at | humanDateWithTimeStamp(timezone) }}</span>
                              </div>
                            </div>
                            <div v-if="item.type == 'proposal'">
                              <div
                                v-if="item.state == 'draft'"
                                class="mt-2"
                              >
                                <span class="text-grey-semi-light">Created on {{ item.created_at | humanDateWithTimeStamp(timezone) }}</span>
                              </div>
                              <div
                                v-if="item.state == 'sent'"
                                class="mt-2"
                              >
                                <span class="text-grey-semi-light">Sent on {{ item.sent_at | humanDateWithTimeStamp(timezone) }}</span>
                                <span
                                  v-if="item.state == 'sent'"
                                  class="block mt-2 text-grey-dark"
                                >Due in {{ item.valid_until | timeFromNow }}</span>
                              </div>
                              <div
                                v-if="item.state == 'accepted'"
                                class="mt-2"
                              >
                                <span class="text-grey-semi-light">Accepted on {{ item.accepted_at | humanDateWithTimeStamp(timezone) }}</span>
                              </div>
                              <div
                                v-if="item.state == 'paid'"
                                class="mt-2"
                              >
                                <span class="text-grey-semi-light">Paid on {{ item.paid_at | humanDateWithTimeStamp(timezone) }}</span>
                              </div>
                            </div>
                            <div v-if="item.type == 'invoice'">
                              <div
                                class="mt-2"
                              >
                                <span
                                  v-if="item.valid_until"
                                  class="text-grey-semi-light"
                                >Due date: {{ item.valid_until | humanDateNoConvert }}</span>
                                <span
                                  v-else
                                  class="text-grey-semi-light"
                                >Due date: To be generated after finishing project</span>
                              </div>
                            </div>

                            <button
                              v-if="item.type == 'email'"
                              class="bg-grey-f9 p-4 rounded mt-3 text-left border border-grey-f9 hover:border-grey-df focus:outline-none"
                              @click="item.state === 'draft' ? editEmail(item.email_type, item.item_id) : viewEmail(item.email_type, item.id)"
                            >
                              <p class="break-words text-grey-darker leading-loosen">
                                {{ item.content | excerptEmailContent }}
                              </p>
                            </button>

                            <div class="flex mt-4">
                              <div class="w-1/2">
                                <span
                                  class="item-invoice-state capitalize"
                                  :class="item.state"
                                >
                                  <span v-if="item.state != 'answered'">{{ item.state }}</span>
                                  <span v-else>Finished</span>
                                </span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="w-full md:w-2/5">
          <div v-click-outside="closeCreateMenuDropdown">
            <div
              class="create-menu"
              :class="[isCreateDropdownOpen ? 'active' : '']"
              @click="toggleCreateMenuDropdown"
            >
              <div class="create-menu__select">
                <div>
                  <span class="plus-ico-button">
                    <img
                      src="@/assets/img/icons/ico-plus-no-bg.svg"
                      width="12"
                    >
                  </span>
                </div>
                <div>
                  <div class="text">
                    Create
                  </div>
                </div>
              </div>
              <div
                v-show="isCreateDropdownOpen"
                class="create-menu__dropdown"
              >
                <router-link
                  :to="{path: '/projects/'+project.id+'/proposal/choose'}"
                  class="flex items-center"
                >
                  <div class="mr-4">
                    <img
                      src="@/assets/img/icons/proposal.svg"
                      width="22"
                    >
                  </div>
                  <div>
                    Create Proposal
                  </div>
                </router-link>
                <router-link
                  :to="{path: '/projects/'+project.id+'/invoice/choose'}"
                  class="flex items-center"
                >
                  <div class="mr-4">
                    <img
                      src="@/assets/img/icons/invoice.svg"
                      width="22"
                    >
                  </div>
                  <div>
                    Create Invoice
                  </div>
                </router-link>
                <a
                  class="flex items-center cursor-pointer"
                  @click="newEmail"
                >
                  <div class="mr-4">
                    <img
                      src="@/assets/img/icons/ico-send-orange.svg"
                      width="22"
                    >
                  </div>
                  <div>
                    Send an email
                  </div>
                </a>
                <router-link
                  :to="{path: '/projects/'+project.id+'/questionnaire/choose'}"
                  class="flex items-center cursor-pointer"
                >
                  <div class="mr-4">
                    <img
                      src="@/assets/img/icons/ico-questionnaire.svg"
                      width="22"
                    >
                  </div>
                  <div>
                    Create a questionnaire
                  </div>
                </router-link>
              </div>
            </div>
          </div>

          <div
            v-show="!isMobile || (isMobile && view == 'files') "
            class="info-block first"
          >
            <project-files-overview
              :project="project"
              :currencies="currencies"
              @send="sendMail"
              @delete="(item) => deleteItem(0, item, project.id)"
              @share="(item) => copyShareLink(project.id, item.type, item.token)"
              @markInvoicePaid="(item) => markAsPaid(item.id, Number(item.due_total))"
            />
          </div>

          <div
            v-show="!isMobile || (isMobile && view == 'tasks') "
            class="info-block"
          >
            <h3>Tasks</h3>
            <div class="mx-auto max-w-md">
              <div
                v-for="(task, index) in tasks"
                :key="index"
                class="task hover:bg-blue-washed-light"
              >
                <div class="mt-px">
                  <v-checkbox
                    :id="`task-${task.id}`"
                    v-model="task.is_completed"
                    :checked="task.is_completed"
                    classes="small"
                    label=""
                    @change.native="updateTask(task)"
                  />
                </div>
                <div class="w-full">
                  <TextareaAutosize
                    v-model="task.name"
                    placeholder="Name of the task"
                    rows="1"
                    :min-height="20"
                    :max-height="120"
                    :class="{ 'line-through': task.is_completed }"
                    @change.native="updateTask(task)"
                  />
                  <div class="flex flex-grow justify-between -mt-3">
                    <div class="text-sm relative">
                      <div class="task-date">
                        <v-date-picker
                          v-model="task.due_date"
                          class="datepicker"
                          :input-props="{
                            placeholder: &quot;Set deadline&quot;,
                            readonly: true
                          }"
                          :masks="{L: 'MMM D'}"
                          :popover="{ visibility: 'click' }"
                          @popoverDidHide="updateTask(task)"
                        >
                          <template v-slot="{ inputValue, inputEvents }">
                            <input
                              type="text"
                              placeholder="Set a deadline"
                              :value="inputValue"
                              v-on="inputEvents"
                            >
                          </template>
                        </v-date-picker>
                      </div>
                    </div>
                    <div class="text-right">
                      <span
                        class="delete text-blue underline cursor-pointer text-right text-sm"
                        @click="removeTask(task.id, index)"
                      >Delete</span>
                    </div>
                  </div>
                </div>
              </div>
              <div
                v-if="tasks.length == 0"
                class="text-grey-darker"
              >
                You have no tasks
              </div>
              <div
                class="mt-2 underline text-sm green cursor-pointer"
                @click="addTask"
              >
                + Add a task
              </div>
            </div>
          </div>
          <div
            v-show="!isMobile || (isMobile && view == 'notes')"
            id="note-block"
            class="info-block"
          >
            <h3>Notes</h3>

            <textarea-autosize
              v-model="project.notes"
              v-debounce:500ms="saveNotes"
              :min-height="100"
              :max-height="450"
              @focus="onFocusNotes"
              @blur="onBlurNotes"
            />
          </div>
          <div
            v-if="project.contact"
            v-show="!isMobile || (isMobile && view == 'contact')"
            class="info-block"
          >
            <h3>Client contact details</h3>

            <p>{{ project.contact.first_name }} {{ project.contact.last_name }}</p>
            <p v-show="project.contact.company">
              {{ project.contact.company }}
            </p>
            <p>{{ project.contact.email }}</p>
            <p v-show="project.contact.phone">
              {{ project.contact.phone }}
            </p>

            <div class="mt-2">
              <a
                class="green cursor-pointer underline text-sm"
                @click="editContact"
              >
                Edit contact details
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div
      class="mobile-bottom-menu"
      :class="{ 'is-active': isMobileMenuOpen }"
    >
      <div
        class="bg"
        @click="handleCloseMobileMenu"
      />
      <div class="content">
        <div
          class="close-container"
          @click.capture="handleCloseMobileMenu"
        >
          <a
            href="#"
            class="close"
          />
        </div>
        <router-link
          class="flex items-center icon"
          :to="`/${mobileItem.type}s/preview/${mobileItem.id}`"
        >
          <div
            class="w-1/12 text-center"
          >
            <svg
              width="21px"
              height="13px"
              viewBox="0 0 21 13"
              version="1.1"
              xmlns="http://www.w3.org/2000/svg"
              xmlns:xlink="http://www.w3.org/1999/xlink"
            >
              <g
                id="04---Desktop-Pages"
                stroke="none"
                stroke-width="1"
                fill="none"
                fill-rule="evenodd"
              >
                <g
                  id="8.1---New-Proposal"
                  transform="translate(-1179.000000, -27.000000)"
                  fill="#B1B6CD"
                  fill-rule="nonzero"
                >
                  <g
                    id="view-1"
                    transform="translate(1179.666667, 27.000000)"
                  >
                    <path
                      id="Shape"
                      d="M19.5225149,4.93745923 C17.3183483,2.51245923 13.6625149,-0.0508741058 9.99584825,0.000792560846 C6.32918158,-0.0517074392 2.67334825,2.51329256 0.469181584,4.93745923 C-0.156393861,5.63687384 -0.156393861,6.69471128 0.469181584,7.39412589 C2.64751492,9.79329256 6.25168158,12.3341259 9.86168158,12.3341259 L10.1183483,12.3341259 C13.7408483,12.3341259 17.3441816,9.79329256 19.5250149,7.39329256 C20.1497618,6.69351492 20.1486852,5.63596346 19.5225149,4.93745923 Z M6.16251492,6.16745923 C6.16251492,4.05036769 7.87875671,2.33412589 9.99584825,2.33412589 C12.1129398,2.33412589 13.8291816,4.05036769 13.8291816,6.16745923 C13.8291816,8.28455077 12.1129398,10.0007926 9.99584825,10.0007926 C7.87875671,10.0007926 6.16251492,8.28455077 6.16251492,6.16745923 Z"
                    />
                    <circle
                      id="Oval"
                      cx="9.99584825"
                      cy="6.16745923"
                      r="1.66666667"
                    />
                  </g>
                </g>
              </g>
            </svg>
          </div>
          <div class="ml-2 text-grey-darker text-sm">
            View
          </div>
        </router-link>

        <router-link
          v-if="mobileItem.type != 'questionnaire' || (mobileItem.type == 'questionnaire' && mobileItem.state != 'answered')"
          :to="`${project.id}/${mobileItem.type}/${mobileItem.id}`"
          class="flex items-center icon"
        >
          <div
            class="w-1/12 text-center"
          >
            <svg
              width="18px"
              height="18px"
              viewBox="0 0 18 18"
              version="1.1"
              xmlns="http://www.w3.org/2000/svg"
              xmlns:xlink="http://www.w3.org/1999/xlink"
            >
              <g
                id="04---Desktop-Pages"
                stroke="none"
                stroke-width="1"
                fill="none"
                fill-rule="evenodd"
              >
                <g
                  id="8.1---New-Proposal"
                  transform="translate(-1001.000000, -24.000000)"
                  fill="#B1B6CD"
                  fill-rule="nonzero"
                >
                  <g
                    id="pencil-write-2"
                    transform="translate(1001.000000, 24.000000)"
                  >
                    <path
                      id="Path"
                      d="M10.299,11.44725 C10.1554712,11.5909254 9.98426193,11.7039593 9.79575,11.7795 L7.1445,12.84 C6.58730743,13.063031 5.95080782,12.9324635 5.52642216,12.5080778 C5.10203649,12.0836922 4.97146903,11.4471926 5.1945,10.89 L6.25575,8.23875 C6.33097937,8.05003456 6.4437597,7.87856763 6.58725,7.73475 L11.7,2.625 L2.679,2.625 C1.38828867,2.62623961 0.342153633,3.67203914 0.3405,4.96275 L0.3405,15.28725 C0.342565793,16.5777898 1.38845965,17.6233482 2.679,17.625 L13.00275,17.625 C14.2931684,17.6233472 15.3388472,16.5776684 15.3405,15.28725 L15.3405,6.40575 L10.299,11.44725 Z"
                    />
                    <path
                      id="Path"
                      d="M15.09975,0.81375 L7.383,8.5305 C7.34719882,8.56658804 7.31890386,8.60941284 7.29975,8.6565 L6.23925,11.30775 C6.18334681,11.4472361 6.21610991,11.6066242 6.3225,11.71275 C6.42932022,11.8173182 6.58749764,11.8495993 6.72675,11.79525 L9.375,10.73475 C9.42221092,10.7158317 9.46508008,10.6875074 9.501,10.6515 L17.22,2.9355 C17.6100657,2.55902569 17.7666452,2.001377 17.6295572,1.47688575 C17.4924692,0.952394508 17.0830477,0.542683356 16.5586535,0.405224455 C16.0342594,0.267765553 15.4765001,0.423950601 15.09975,0.81375 L15.09975,0.81375 Z"
                    />
                  </g>
                </g>
              </g>
            </svg>
          </div>
          <div class="ml-2 text-grey-darker text-sm">
            Edit
          </div>
        </router-link>
        <a
          v-if="mobileItem.state == 'draft'"
          class="flex items-center icon"
          @click="sendMail(mobileItem)"
        >
          <div
            class="w-1/12 text-center"
          >
            <svg
              width="20px"
              height="19px"
              viewBox="0 0 20 19"
              version="1.1"
              xmlns="http://www.w3.org/2000/svg"
              xmlns:xlink="http://www.w3.org/1999/xlink"
            >
              <g
                id="04---Desktop-Pages"
                stroke="none"
                stroke-width="1"
                fill="none"
                fill-rule="evenodd"
              >
                <g
                  id="8.1---New-Proposal"
                  transform="translate(-1293.000000, -24.000000)"
                  fill="#B1B6CD"
                  fill-rule="nonzero"
                >
                  <g
                    id="send-email"
                    transform="translate(1293.000000, 24.000000)"
                  >
                    <path
                      id="Path"
                      d="M19.85,0.0966666667 C19.7309544,-0.002708744 19.5665702,-0.0277516553 19.4233333,0.0316666667 L0.256666667,7.94833333 C0.0999508816,8.01368953 -0.00142976764,8.16760488 0.000377405786,8.33739273 C0.00223340071,8.50718059 0.106910791,8.65887309 0.265,8.72083333 L5.54166667,10.77 C5.6713041,10.8205377 5.8176129,10.8030306 5.93166667,10.7233333 L12.9316667,5.745 C13.1094492,5.61646365 13.3564456,5.64779779 13.4965006,5.81665525 C13.6365555,5.98551271 13.6216874,6.23404436 13.4625,6.385 L7.62916667,12.0058333 C7.5476907,12.0843698 7.50166384,12.1926683 7.50166667,12.3058333 L7.50166667,17.9166667 C7.50221133,18.1044087 7.62824568,18.268599 7.80946716,18.3176514 C7.99068865,18.3667038 8.18233295,18.2885018 8.2775,18.1266667 L10.9166667,13.6058333 C10.9739417,13.5083386 11.0983292,13.4740121 11.1975,13.5283333 L16.0525,16.195 C16.1677777,16.2585343 16.306298,16.2638619 16.4261152,16.2093697 C16.5459324,16.1548775 16.6329557,16.0469739 16.6608333,15.9183333 L19.9941667,0.501666667 C20.0233323,0.350495073 19.9681262,0.195407216 19.85,0.0966666667 Z"
                    />
                  </g>
                </g>
              </g>
            </svg>
          </div>
          <div class="ml-2 text-grey-darker text-sm">
            Send
          </div>
        </a>
        <div
          v-if="mobileItem.type == 'invoice' && mobileItem.state == 'sent'"
          class="flex items-center icon"
          @click="markAsPaid(mobileItem.id, mobileItem.remaining)"
        >
          <div
            class="w-1/12 text-center"
          >
            <svg
              width="21px"
              height="15px"
              viewBox="0 0 21 15"
              version="1.1"
              xmlns="http://www.w3.org/2000/svg"
              xmlns:xlink="http://www.w3.org/1999/xlink"
            >
              <g
                id="04---Desktop-Pages"
                stroke="none"
                stroke-width="1"
                fill="none"
                fill-rule="evenodd"
              >
                <g
                  id="8.1---New-Proposal"
                  transform="translate(-1061.000000, -26.000000)"
                  fill="#B1B6CD"
                  fill-rule="nonzero"
                >
                  <g
                    id="accounting-bills"
                    transform="translate(1061.000000, 26.000000)"
                  >
                    <path
                      id="Shape"
                      d="M17.8282186,11.193 L17.8282186,1.71325 C17.8316305,1.46539804 17.736521,1.22630741 17.5637244,1.04858787 C17.3909279,0.870868338 17.1546026,0.769081626 16.90675,0.765625 L1.2495,0.765625 C1.00164735,0.769081626 0.765322117,0.870868338 0.592525561,1.04858787 C0.419729005,1.22630741 0.324619483,1.46539804 0.328031423,1.71325 L0.328031423,11.193 C0.324619483,11.440852 0.419729005,11.6799426 0.592525561,11.8576621 C0.765322117,12.0353817 1.00164735,12.1371684 1.2495,12.140625 L16.90675,12.140625 C17.1546026,12.1371684 17.3909279,12.0353817 17.5637244,11.8576621 C17.736521,11.6799426 17.8316305,11.440852 17.8282186,11.193 Z M9.078125,9.078125 C7.62837753,9.078125 6.453125,7.90287247 6.453125,6.453125 C6.453125,5.00337753 7.62837753,3.828125 9.078125,3.828125 C10.5278725,3.828125 11.703125,5.00337753 11.703125,6.453125 C11.703125,7.14931829 11.4265633,7.81699731 10.9342803,8.3092803 C10.4419973,8.80156329 9.77431829,9.078125 9.078125,9.078125 Z"
                    />
                    <path
                      id="Path"
                      d="M20.015625,3.171875 C19.6531881,3.171875 19.359375,3.46568813 19.359375,3.828125 L19.359375,13.453125 C19.359375,13.5739373 19.2614373,13.671875 19.140625,13.671875 L3.390625,13.671875 C3.02818813,13.671875 2.734375,13.9656881 2.734375,14.328125 C2.734375,14.6905619 3.02818813,14.984375 3.390625,14.984375 L19.140625,14.984375 C19.9859112,14.9834106 20.6709106,14.2984112 20.671875,13.453125 L20.671875,3.828125 C20.671875,3.46568813 20.3780619,3.171875 20.015625,3.171875 Z"
                    />
                  </g>
                </g>
              </g>
            </svg>
          </div>
          <div class="ml-2 text-grey-darker text-sm">
            Mark as paid
          </div>
        </div>
        <div
          v-if="mobileItem.state != 'draft'"
          class="flex items-center icon"
          @click="copyShareLink(project.id, mobileItem.type, mobileItem.token)"
        >
          <div
            class="w-1/12 text-center"
          >
            <svg
              width="18px"
              height="18px"
              viewBox="0 0 18 18"
              version="1.1"
              xmlns="http://www.w3.org/2000/svg"
              xmlns:xlink="http://www.w3.org/1999/xlink"
            >
              <g
                id="04---Desktop-Pages"
                stroke="none"
                stroke-width="1"
                fill="none"
                fill-rule="evenodd"
              >
                <g
                  id="8.1---New-Proposal"
                  transform="translate(-1122.000000, -25.000000)"
                  fill="#B1B6CD"
                  fill-rule="nonzero"
                >
                  <g
                    id="share"
                    transform="translate(1122.000000, 25.000000)"
                  >
                    <path
                      id="Path"
                      d="M14.4,10.08 C13.4253464,10.0807172 12.493051,10.4784896 11.81808,11.1816 L7.86312,9.20376 C7.89701789,9.01764823 7.91603803,8.82913213 7.92,8.64 C7.91607775,8.45086546 7.89705729,8.2623462 7.86312,8.07624 L11.81808,6.0984 C13.017396,7.33089531 14.9218149,7.54093081 16.3608987,6.59942065 C17.7999825,5.6579105 18.3703018,3.82879543 17.7213439,2.23623237 C17.0723859,0.643669308 15.3860779,-0.26588637 13.698809,0.0665696999 C12.01154,0.39902577 10.7963151,1.88029364 10.8,3.6 C10.8039238,3.7893729 10.8229441,3.97813128 10.85688,4.16448 L6.90192,6.14088 C5.88296984,5.08408644 4.32440273,4.75185467 2.96306841,5.30125517 C1.60173409,5.85065566 0.710357062,7.17162364 0.710357062,8.63964 C0.710357062,10.1076564 1.60173409,11.4286243 2.96306841,11.9780248 C4.32440273,12.5274253 5.88296984,12.1951936 6.90192,11.1384 L10.85688,13.11552 C10.8229046,13.3018631 10.803884,13.4906247 10.8,13.68 C10.8,15.6682251 12.4117749,17.28 14.4,17.28 C16.3882251,17.28 18,15.6682251 18,13.68 C18,11.6917749 16.3882251,10.08 14.4,10.08 Z"
                    />
                  </g>
                </g>
              </g>
            </svg>
          </div>
          <div class="ml-2 text-grey-darker text-sm">
            Share link
          </div>
        </div>
        <div
          class="flex items-center icon"
          @click="deleteItem(mobileItem.index, mobileItem, project.id)"
        >
          <div
            class="w-1/12 text-center"
          >
            <svg
              width="15px"
              height="19px"
              viewBox="0 -2 15 17"
              version="1.1"
              xmlns="http://www.w3.org/2000/svg"
              xmlns:xlink="http://www.w3.org/1999/xlink"
            >
              <g
                id="04---Desktop-Pages"
                stroke="none"
                stroke-width="1"
                fill="none"
                fill-rule="evenodd"
              >
                <g
                  id="8.1---New-Proposal"
                  transform="translate(-1239.000000, -25.000000)"
                  fill="#B1B6CD"
                  fill-rule="nonzero"
                >
                  <g
                    id="Group-2"
                    transform="translate(1239.333333, 25.000000)"
                  >
                    <path
                      id="Shape"
                      d="M12.2500687,6.28636118 L1.7500687,6.28636118 C1.55676903,6.28636118 1.4000687,6.44306151 1.4000687,6.63636118 L1.4000687,15.3863612 C1.4000687,16.1595598 2.02687005,16.7863612 2.8000687,16.7863612 L11.2000687,16.7863612 C11.9732673,16.7863612 12.6000687,16.1595598 12.6000687,15.3863612 L12.6000687,6.63636118 C12.6000687,6.44306151 12.4433684,6.28636118 12.2500687,6.28636118 Z"
                    />
                    <path
                      id="Shape"
                      d="M13.2454687,3.38906118 C12.3370996,2.96622621 11.3593784,2.71218945 10.3600687,2.63936118 C9.98122814,1.08994834 8.59267365,-7.10542736e-15 6.9976187,-7.10542736e-15 C5.40256375,-7.10542736e-15 4.01400926,1.08994834 3.6351687,2.63936118 C2.66491022,2.71627349 1.71501857,2.95895518 0.826768696,3.35686118 C0.35158701,3.5583228 0.0313780649,4.01138728 -1.09245946e-12,4.52656118 C-0.0025543733,4.71390486 0.0700358039,4.89448061 0.201596649,5.02788331 C0.333157495,5.16128601 0.512706652,5.23636118 0.700068696,5.23636118 L13.3000687,5.23636118 C13.6826044,5.23636118 13.9943307,4.92935381 14.0000687,4.54686118 C13.9887019,4.04897746 13.6963841,3.60046757 13.2454687,3.38906118 Z M6.9839687,1.40036118 C7.75562862,1.38538353 8.46829867,1.8116814 8.8200687,2.49866118 C7.60776446,2.42798917 6.39237294,2.42798917 5.1800687,2.49866118 C5.52033471,1.81631511 6.22156701,1.38937158 6.9839687,1.40036118 L6.9839687,1.40036118 Z"
                    />
                  </g>
                </g>
              </g>
            </svg>
          </div>
          <div class="ml-2 text-grey-darker text-sm">
            Delete
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import filter from 'lodash.filter'
import config from '@/config'
import auth from '@/auth'
import { format } from 'date-fns'
import { logException } from '@/helpers'
import { createCancelSource } from '@/http'
import ProjectContactSlideIn from './Projects/ProjectContactSlideIn'
import ProjectEmailSlideIn from './Projects/ProjectEmailSlideIn'
import { convertToTimeZone } from 'date-fns-timezone'
import vClickOutside from 'v-click-outside'
import { getWorkflows } from '../api/Workflows'
import ActionProgress from '../components/ActionProgress'
import ProjectWorkflowSummary from '../components/ProjectWorkflowSummary'
import ProjectFilesOverview from '../components/ProjectFilesOverview'
import Icon from '../components/Icon'
let cancelSource

  export default {
    components: {
      Icon,
      ProjectFilesOverview,
      ProjectWorkflowSummary,
      ActionProgress,
      ProjectContactSlideIn,
      ProjectEmailSlideIn,
    },
    directives: {
      clickOutside: vClickOutside.directive
    },
    data () {
      return {
        metaTitle: 'Loading',
        multiDays: false,
        projectDateError: false,
        editProject:{
          name: null,
          state: null,
          date: null,
          endDate: null,
          location: null,
          currency_id:null,
          language: null,
          workflow_id: null,
        },
        workflows: [],
        isCreateDropdownOpen: false,
        isLoading: true,
        currencies: {},
        currency:{},
      	project: {},
        proposals: {},
        invoices: {},
        view: 'activities',
      	tag: '',
				tags: [],
				autocompleteTagItems: [],
				tasks: [],
        items: [],
        user: auth.user(),
        debounce: null,
        isSendingMail: false,

        timezone: '',
        showEditNotes: false,
        showContactSlide: false,
        showEmailSlide: false,
        addPayment: {
          id: null,
          date: null,
          amount:0,
        },
        remainingPaymentAmount:0,
        isShowProjectDetailModal: false,
        multiCurrencies: 0,
        isMobile: false,
        isMobileMenuOpen: false,
        mobileItem: {},
        showNotesSaved: false,
        languageContent: {
          content: 'The language to display on the client view for your proposals and invoices.'
        },
        activityViewFilter: 'all',
        emailEditMode: 'edit',
        totalEmails: 0,
        tooltip: {
          delay: 0,
          show: false,
          offset: 0,
          trigger: 'hover',
          classes: ['header-tooltip', 'normal-case'],
          placement: 'bottom'
        },
        email: {
          emailType: '',
          emailId: null,
          isBlankEmail: false,
          autocompleteEmailTagItems: [],
          selectEmailTemplate: '',
          emailTags: [],
          templates: [],
          fromEmailView: '',
          toEmail: '',
          fromEmail: '',
          subjectEmail:'',
          contentEmail: '',
          emailUpdatePending: false,
        },
        workflow: null
      }
    },
    computed: {
      formattedDate() {
        if (!this.project.date) return ''

        const startDate = new Date(this.project.date)
        const startDay = startDate.toLocaleString('default', { day: 'numeric', timeZone: 'CET' })
        const startMonth = startDate.toLocaleString('default', { month: 'short', timeZone: 'CET' })
        const startYear = startDate.toLocaleString('default', { year: 'numeric', timeZone: 'CET' })

        if (this.project.end_date && this.project.end_date !== this.project.date) {
          const endDate = new Date(this.project.end_date)
          const endDay = endDate.toLocaleString('default', { day: 'numeric', timeZone: 'CET' })
          const endMonth = endDate.toLocaleString('default', { month: 'short', timeZone: 'CET' })
          const endYear = endDate.toLocaleString('default', { year: 'numeric', timeZone: 'CET' })

          // Check if the start and end dates are in the same year
          if (startYear === endYear) {
            // Further check if they are in the same month
            if (startMonth === endMonth) {
              return `${startMonth} ${startDay}-${endDay}, ${startYear}`
            } else {
              return `${startMonth} ${startDay} - ${endMonth} ${endDay}, ${startYear}`
            }
          } else {
            // Handle different years
            return `${startMonth} ${startDay}, ${startYear} - ${endMonth} ${endDay}, ${endYear}`
          }
        } else {
          return `${startMonth} ${startDay}, ${startYear}`
        }
      },
      activeLanguages() {
        const languages = filter(this.user.companySettings.locales, (language => language.isActive))

        return languages
      },
    },
		watch: {
		  'tag': 'initTagItems',
      'toEmail': 'initEmailTagItems',
		},
    beforeDestroy () {
      if (typeof window !== 'undefined') {
        window.removeEventListener('resize', this.onResize, { passive: true })
      }
    },
    async mounted () {
      this.loadWorkflows()
      this.onResize()
      window.addEventListener('resize', this.onResize, { passive: true })

      try {
        const { data } = await this.$api.get('currency').list()
        this.currencies = data.currencies
      } catch(e){
        this.$toasted.global.api_error(e)
        logException(e)
      }

      await this.initData()
      this.metaTitle = `Project: ${this.project.name}`
		},
    methods: {
      toggleCreateMenuDropdown(){
        if(this.isCreateDropdownOpen){
          this.isCreateDropdownOpen = false
        } else {
          this.isCreateDropdownOpen = true
        }
      },
      closeCreateMenuDropdown(){
        this.isCreateDropdownOpen = false
      },
      handleCloseMobileMenu(){
        this.isMobileMenuOpen = false
      },
      activityFilter(view){
        this.activityViewFilter = view
      },
      handleOpenMobileMenu(index, item){
        this.mobileItem = item
        this.mobileItem.index = index
        this.isMobileMenuOpen = true
      },
      onResize () {
        this.isMobile = window.innerWidth < 769
      },
      async newEmail () {
        const signature = (!this.user.me.email_signature) ? '' : this.user.me.email_signature

        this.email.emailUpdatePending = false
        this.email.subjectEmail = ''
        this.email.contentEmail = `<p>&nbsp;</p><p>${signature}</p>`
        this.email.emailUpdatePending = true
        this.email.isBlankEmail = true

        this.email.emailId = this.project.id
        this.email.emailType = 'project'
        this.email.toEmail = ''
        this.email.emailTags = []
        this.email.emailTags.push({
          text: this.project.contact.email,
        })

        this.emailEditMode = 'edit'
        this.showEmailSlide = true
        await this.loadMailDraft('project', this.project.id, '')

      },
      async updateProjectState (state) {
        const canDelete = await this.$alert.confirm({
          title: 'You are about to update the status of this project.',
          text: `The project status will change to: ${state}.`,
        })

        if (canDelete) {
          try {
              const { data } = await this.$api.get('projects').updateState(this.$route.params.id, state)
              await this.initData()

              this.$toasted.global.general_success({
                message : `Project status changed to ${state}.`
              })
          } catch(e){
            logException(e)
          }
        }
      },
      showDatePicker (taskId) {
        this.$nextTick(() => this.$refs['datepicker'+taskId][0].$el.focus())
      },
      async initData () {
        this.items = []
        this.isLoading = true
        cancelSource = createCancelSource()

        try {
          const { data } = await this.$api.get('projects').load(this.$route.params.id, {
            cancelToken: cancelSource.token
          })

          this.timezone = this.user.companySettings.timezone
          this.multiCurrencies = this.user.companySettings.multiCurrencies

          if (!data.project.currency_id) {
            this.currency = this.user.companySettings.currency
          } else {
            this.currency = data.project.currency
          }

          this.project = data.project
          this.editProject.name = data.project.name
          this.editProject.state = data.project.state
          this.editProject.workflow_id = data.project.workflow_id
          this.editProject.currency_id = this.currency.id
          this.editProject.language = data.project.locale
          this.workflow = data.workflow

          if (data.project.date) {
            let date = new Date(data.project.date)
            this.editProject.date  = convertToTimeZone(date, { timeZone: 'Etc/UTC' })
          }

          if (data.project.end_date) {
            let date = new Date(data.project.end_date)
            this.editProject.endDate  = convertToTimeZone(date, { timeZone: 'Etc/UTC' })

            if (this.editProject.date && this.editProject.date.getTime() !== this.editProject.endDate.getTime()) {
              this.multiDays = true
            }
          }

          this.editProject.location = data.project.location
          this.items = data.items
          this.totalEmails = data.totalEmails

          this.tasks = []
          this.project.tasks.forEach(task => {
            let taskDate = (task.due_date !== null) ? new Date(task.due_date) : null
            this.tasks.push({
              id: task.id,
              name: task.name,
              project_id: task.project_id,
              company_id: task.company_id,
              is_completed: task.is_completed,
              due_date: taskDate
            })
          })

          this.email.fromEmailView = `From: ${this.user.companySettings.senderName} <${this.user.companySettings.senderEmail}>`
          this.email.fromEmail = this.user.companySettings.senderEmail

          this.email.emailTags.push({
            text: data.project.contact.email,
          })

          await this.loadTags()
          this.isLoading = false

        } catch(e){
          setTimeout(() => this.$router.replace({ name: 'Projects' }), 0)
          logException(e)
        }
      },
      dateFormatSave(date) {
        return format(date, 'YYYY-MM-DD')
      },
      textareaResize(e) {
        e.target.style.height = 'auto'
        e.target.style.minHeight = (e.target.scrollHeight) + 'px'
      },
      switchView(view){
        this.view = view
      },
      dateFormatter(date) {
        return null
      },
      updateTags(newTags) {
        this.autocompleteTagItems = []
        this.tags = newTags
      },
      async sendMail (item) {
        this.$toasted.clear()
        if(item.type == 'invoice'){
          if(item.issued_at == null || item.valid_until == null){
            this.$toasted.global.general_error({
              message : 'Invoice cannot be send without an issued or a due date.'
            })
            return false
          }
        }

        this.isMobileMenuOpen = false
        this.email.isBlankEmail = false
        this.showEmailSlide = true
        this.emailEditMode = 'edit'
        this.email.toEmail = ''
        this.email.emailType = item.type

        await this.loadMailDraft(item.type, item.id, item.token)
      },
      async loadMailTemplates(){
        try {
          const { data } = await this.$api.get('templateMail').list(this.user.company.id)
          this.email.templates = []

          data.mails.forEach(template => {
            this.email.templates.push({template})
          })
        } catch(e){
          logException(e)
        }
      },
      async loadMailLog(type, id){
        try {
          const { data } = await this.$api.get('mail').loadLog(`${id}`, `${type}`)

          this.email.emailType = type
          this.email.emailId = id
          this.email.toEmail = data.to
          this.email.subjectEmail = data.subject
          this.email.contentEmail = data.content
          this.email.emailUpdatePending = true

        } catch(e){
          logException(e)
        }
      },
      async loadMailDraft(type, id, token){
        await this.loadMailTemplates()
        this.email.selectEmailTemplate = ''

        try {
          const { data } = await this.$api.get('mail').loadDraft(`${id}`, `${type}`)

          this.email.emailType = type
          this.email.emailId = id

          if(data.result != 'Empty'){

            this.email.emailTags = []
            data.toEmails.forEach(email => {
              this.email.emailTags.push({
                text: email,
              })
            })

            this.email.subjectEmail = data.subject
            this.email.contentEmail = data.content
            this.email.emailUpdatePending = true
          } else {
            if(type != 'project'){
              this.generateEmailContent(this.project.id, type, token)
            }
          }

        } catch(e){
          logException(e)
        }
      },
      generateEmailContent(projectId, type, token){
        this.email.emailTags = []
        this.email.emailTags.push({
          text: this.project.contact.email,
        })

        const signature = (!this.user.me.email_signature) ? '' : this.user.me.email_signature
        const link = `${config.frontBaseUrl}/view/${projectId}/${type}/${token}`

        this.email.contentEmail = `<p>Hi ${this.project.contact.first_name}, </p><p></p><p>I hereby send you my ${type}! Please contact me if you have any questions or if things are not clear. I’m glad to help you further. </p><p></p><p>Link to ${type}: ${link}<a href="#"></a> </p><p>${signature}</p>`
        this.email.emailUpdatePending = true
      },
      async initEmailTagItems(){
        if (this.email.toEmail.length === 0) return

        this.email.autocompleteTagItems.push({
          text: this.email.toEmail
        })
      },
      async deleteItem(index, item, projectId){

        this.isMobileMenuOpen = false

        let text = 'If you delete this, sent item will no longer accessible by the client.'
        if(item.type == 'email'){
          text = 'If you delete this, you will lose it\'s content.'
        }
        const canDelete = await this.$alert.confirm({
          title: `Are you sure you want to delete this ${item.type}?`,
          text: text,
        })

        if (canDelete) {
          try {
            const { data } = await this.$api.get('projects').deleteItem(item, projectId)
            await this.initData()
          } catch(e){
            logException(e)
          }
        }
      },
      copyShareLink(id, type, token){
        this.isMobileMenuOpen = false
        const link = `${config.frontBaseUrl}/view/${id}/${type}/${token}`
        let dummy = document.createElement('textarea')
        document.body.appendChild(dummy)
        dummy.value = link
        dummy.select()
        document.execCommand('copy')
        document.body.removeChild(dummy)

        this.$toasted.global.general_success({
          message : 'Share link has been copied to your clipboard.'
        })
      },
      async initTagItems() {
        if (this.tag.length === 0) return

        cancelSource = createCancelSource()
        try {
          const { data } = await this.$api.get('tags').search(this.tag, {
            cancelToken: cancelSource.token
          })

          const { tags } = data
          this.autocompleteTagItems = tags.map(item => {
            const name = JSON.parse(item.name)
            return { text: name.en }
          })

        } catch(e){
          logException(e)
        }
      },
      async loadTags(){
        try {
          const { data } = await this.$api.get('projects').loadTags(this.$route.params.id)
          this.tags = []

          const tags = data.project.tags
          for (const key of Object.keys(tags)) {
            this.tags.push({
              text: tags[key].name.en,
            })
          }
        } catch(e){
          logException(e)
        }
      },
      async updateTask(task){
        let date = null
        if(task.due_date != null){
          date = format(task.due_date, 'YYYY-MM-DD')
        }

        try {
          const { data } = await this.$api.get('projects').updateTask(this.project.id, task.id, {
            name: task.name,
            due_date: date,
            is_completed: task.is_completed,
          })

        } catch(e){
          logException(e)
        }
      },
      async removeTask(id, index){
        try {
          const { data } = await this.$api.get('projects').deleteTask(this.project.id, id)
          this.tasks.splice(index, 1)

        } catch(e){
          logException(e)
        }
      },
      async addTask(){
        try {
          const { data } = await this.$api.get('projects').createTask(this.project.id)
          this.tasks.push({
            id: data.task.id,
            name: '',
            due_date: null,
            is_completed: false,
          })

        } catch(e){
          logException(e)
        }
      },
      async unarchive(){
        const canDelete = await this.$alert.confirm({
          title: 'Are you sure you want to unarchive this project?',
          text: 'You can always archive the project again.',
        })

        if (canDelete) {
          try {
            const { data } = await this.$api.get('projects').unarchive(this.$route.params.id)
          } catch(e){
            logException(e)
          }
        }
      },
      async archive(){
        const canDelete = await this.$alert.confirm({
          title: 'Are you sure you want to archive this project?',
          text: 'You can always unarchive the project later.',
        })

        if (canDelete) {
          try {
            const { data } = await this.$api.get('projects').archive(this.$route.params.id)
            setTimeout(() => this.$router.replace({ name: 'Projects' }), 0)
          } catch(e){
            logException(e)
          }
        }
      },
      async deleteProject(){
        const canDelete = await this.$alert.confirm({
          title: 'Are you sure you want to delete this project?',
          text: 'If you delete this, everything will be inaccessible.',
        })

        if (canDelete) {
          try {
            const { data } = await this.$api.get('projects').delete(this.$route.params.id)
            setTimeout(() => this.$router.replace({ name: 'Projects' }), 0)
          } catch(e){
            logException(e)
          }
        }
      },
      showEditProjectModal(){
        this.$modal.show('editProject')
      },
      async saveProject () {
        cancelSource = createCancelSource()
        let date = (this.editProject.date) ? format(this.editProject.date, 'YYYY-MM-DD') : null
        let endDate = (this.editProject.endDate && this.multiDays) ? format(this.editProject.endDate, 'YYYY-MM-DD') : null

        try {
          const { data } = await this.$api.get('projects').update(
            this.$route.params.id,
            {
              project: this.editProject,
              projectDate: date,
              projectEndDate: endDate
            },
            { cancelToken: cancelSource.token })

            this.project = data.project
            this.currency = data.project.currency
            this.editProject.name = data.project.name
            this.editProject.state = data.project.state
            this.editProject.language = data.project.locale

            if (data.project.date){
              let date = new Date(data.project.date)
              this.editProject.date  = convertToTimeZone(date, { timeZone: 'Etc/UTC' })
            }
            if (data.project.end_date && this.multiDays) {
              let date = new Date(data.project.end_date)
              this.editProject.endDate  = convertToTimeZone(date, { timeZone: 'Etc/UTC' })
            } else {
              this.editProject.endDate = null
            }
            console.log(this.editProject.endDate)
            this.editProject.location = data.project.location

            try {
              const { tags } = await this.$api.get('projects').syncTags(this.$route.params.id, {tags: this.tags})
              this.loadTags()
              this.$modal.hide('editProject')
            } catch(e){
              logException(e)
            }
        } catch(e){
          if (e.response && e.response.status == 422) {
            this.$toasted.error(e.response.data.errors)
          } else {
            this.$toasted.error('Something went wrong, project cannot be saved.')
            logException(e)
          }
        }
      },
      async saveNotes(){
        //this.showNotesSaved = false
        try {
            const { data } = await this.$api.get('projects').saveNotes(this.$route.params.id, {notes: this.project.notes})
            //this.showNotesSaved = true
        } catch(e){
          this.$toasted.global.general_error({
            message : 'Something went wrong, notes cannot be saved.'
          })
        }
      },
      onFocusNotes(){
        this.showNotesSaved = false
      },
      onBlurNotes(){
        this.showEditNotes = false
      },
      async hideContactSlideIn(){
        this.showContactSlide = false
      },
      async savedContactSlideIn(contactId){
        try {
          const { data } = await this.$api.get('contacts').load(contactId)

          await this.initData()
          this.showContactSlide = false

        } catch(e){
          logException(e)
        }
      },
      editContact(){
        this.showContactSlide = true
      },
      async savedEmailSlideIn(){
        await this.initData()
        this.showEmailSlide = false
      },
      async hideEmailSlideIn(){
        this.showEmailSlide = false
      },
      async viewEmail(type, id){
        this.emailEditMode = 'view'
        this.showEmailSlide = true
        await this.loadMailLog(type, id, '')
      },
      async editEmail(type, id){
        this.emailEditMode = 'edit'
        this.showEmailSlide = true
        this.email.toEmail = ''
        this.email.emailType = type
        await this.loadMailDraft(type, id, '')
      },
      async markAsPaid(id, remaining) {
        this.isMobileMenuOpen = false
        this.addPayment.id = id
        this.addPayment.date = new Date()
        this.addPayment.amount = remaining.toFixed(2)
        this.remainingPaymentAmount = remaining.toFixed(2)
        this.$modal.show('paymentModal')
      },
      async recordPayment(){
        if(this.addPayment.date == null || this.addPayment.amount <= 0){
            this.$toasted.global.general_error({
              message : 'Please enter a valid date or amount.'
            })
            return
        }

        let postData = {
          id: this.addPayment.id,
          date: format(this.addPayment.date, 'YYYY-MM-DD'),
          amount: this.addPayment.amount,
          reference: 'paid'
        }

        const { data } = await this.$api.get('invoice').addPayment(this.addPayment.id, postData)
        this.addPayment.id = null
        this.addPayment.date = null
        this.addPayment.amount = 0

        this.$modal.hide('paymentModal')
        await this.initData()
      },
      async checkIntroVideo(){
        let showModal = true
        this.user.me.companySettings.watchIntroVideos.forEach(video => {
          if(video == 'project-detail'){
            showModal = false
          }
        })

        if(showModal){
          this.isShowProjectDetailModal = true
        }
      },
      async showClientPortal(){
        let result = await this.$api.get('projects').createClientPortal(this.project.id)
          const link = `${window.location.origin}/v2/client/${result.data.token}`
          let dummy = document.createElement('textarea')
          document.body.appendChild(dummy)
          dummy.value = link
          dummy.select()
          document.execCommand('copy')
          document.body.removeChild(dummy)

        this.$toasted.global.general_success({
          message : 'Client portal link has been copied to your clipboard.'
        })
      },
      closeProjectDetailModal(){
        this.isShowProjectDetailModal = false
      },
      async loadWorkflows() {
        const res = this.handleErrors(await getWorkflows())
        if (!res) return
        this.workflows = res.data.data
      }
	  },
  }
</script>

<style scoped>
  .modal-content-wrapper {
    overflow-y: auto;
    overflow-x: hidden;
    max-height: calc(100vh - 5px);
  }

  .phase {
    @apply text-center cursor-pointer;
    flex: 2;
  }

  .phase:first-of-type {
    @apply text-left w-1/2;
    flex: 1;
  }

  .phase:last-of-type {
    @apply text-right w-1/2;
    flex: 1;
  }

  .phase-dot {
    @apply bg-grey-black-light rounded-full z-10;
    width: 9px;
    height: 9px;
  }

  .active .phase-dot,
  .semi-active .phase-dot {
    @apply bg-white;
  }

  .active .phase-line:first-of-type {
    @apply bg-white;
  }

  .phase:first-of-type .phase-line:first-of-type {
    @apply hidden;
  }

  .phase:last-of-type .phase-line:last-of-type {
    @apply hidden;
  }

  .semi-active .phase-line {
    @apply bg-white;
  }

  .phase-line {
    @apply bg-grey-black-light flex-1 -mx-1;
    margin-top: 3px;
    height: 3px;
  }
</style>
