<template>
  <span
    :class="{
      'py-1 px-2 rounded-full text-xs whitespace-no-wrap': true,
      'bg-red-light text-red-medium': color === 'red',
      'bg-blue-washed': color === 'blue',
      'bg-blue-82 text-white font-bold': color === 'blue-dark',
      'bg-green-light text-green-medium': color === 'green',
    }"
  >
    <slot />
  </span>
</template>

<script>
export default {
  name: 'DueBadgeText',
  props: {
    color: {
      type: String,
      required: true
    }
  }
}
</script>

<style scoped>

</style>
