<template>
  <div>
    <div
      v-if="items.size === 0"
      class="no-items bg-white border border-grey-lighter rounded p-4"
    >
      There are no pending workflow actions.
    </div>
    <div
      v-else
      class="workflow-items"
    >
      <div
        v-for="[key, value] in items"
        :key="key"
        class="flex bg-white border border-grey-lighter rounded mb-2"
      >
        <div class="ml-4 mt-6 w-24">
          <due-badge
            :date="new Date(key)"
          />
        </div>
        <div class="flex-1 min-w-0 m-4 mb-0">
          <div
            v-for="item in value"
            :key="item.id"
            class="flex justify-between items-center mb-4"
          >
            <action-item
              v-if="item.payload"
              :action="item"
              class="mr-4"
              @click="clickHandler(item)"
            />
            <context-actions
              v-if="item.payload"
              :actions="actionActions(item)"
            />
          </div>
        </div>
      </div>
    </div>
    <div
      v-if="completed.length > 0"
      class="completed-items"
    >
      <div class="my-4 flex items-center">
        <div class="mr-2 text-grey-darker">Done</div>
        <div class="border border-grey-lighter flex-1"></div>
      </div>
      <div
        class="bg-white border border-grey-lighter rounded p-4"
      >
        <div
          v-for="item in completed"
          :key="item.id"
          class="flex py-3 -my-3 items-center"
        >
          <div class="w-24">
            <due-badge-text color="green">
              Done
            </due-badge-text>
          </div>
          <div class="flex-1 min-w-0 mx-4">
            <action-item
              v-if="item.payload"
              :action="item"
            />
          </div>
        </div>
      </div>
    </div>
    <slide-modal
      :open="!!currentItem"
      @background-click="reset"
    >
      <action-editor
        :project="project"
        :action="currentItem"
        :to="currentItem && project.contact.email"
        :template="currentItem && currentItem.payload.template_mail"
        :type="currentItem && getEmailType(currentItem)"
        @sent="$emit('update')"
        @cancel="reset"
        @delete="cancelAction(currentItem)"
      />
    </slide-modal>
  </div>
</template>

<script>
import { format } from 'date-fns'
import ContextActions from './Tasks/ContextActions'
import ActionItem from './Tasks/ActionItem'
import DueBadge from './DueBadge'
import { deleteWorkflowJob } from '../api/WorkflowJobs'
import SlideModal from './SlideModal'
import ActionEditor from './Tasks/ActionEditor'
import DueBadgeText from './DueBadgeText'

export default {
  name: 'ProjectWorkflowSummary',
  components: { DueBadgeText, ActionEditor, SlideModal, DueBadge, ActionItem, ContextActions },
  emits: ['update'],
  props: {
    project: {
      type: Object,
      required: true
    },
    hideTitle: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      completed: [],
      currentItem: null,
    }
  },
  computed: {
    items() {
      return this.groupByDueDate(this.project.workflow_jobs)
    }
  },
  methods: {
    reset() {
      this.currentItem = null
    },
    groupByDueDate (items) {
      const result = new Map()
      this.completed = []
      items.forEach(item => {
        if (item.phase !== this.project.state) {
          return
        }

        const date = format(new Date(item.available_at), 'YYYY-MM-DD')

        if (item.status === 'completed') {
          return this.completed.push(item)
        }

        if (!result.has(date)) {
          result.set(date, [])
        }
        result.get(date).push(item)
      })

      return new Map([...result].sort())
    },
    getEmailType(action) {
      if (action.payload.actionable_type === 'template-proposal') return 'proposal'
      if (action.payload.actionable_type === 'template-questionnaire') return 'questionnaire'
      return 'project'
    },
    actionActions (action) {
      return [
        {
          name: 'Cancel',
          action: () => this.cancelAction(action)
        }
      ]
    },
    async cancelAction (action) {
      const canDelete = await this.$alert.confirm({
        title: 'Are you sure you want to cancel this action?',
      })

      if (!canDelete) return

      const res = this.handleErrors(await deleteWorkflowJob(action.id))
      if (res) {
        this.$emit('update')
      }
    },
    clickHandler(item) {
      let isProposal = item.payload.actionable_type == 'template-proposal';

      if (isProposal && !this.project.date) {
        this.$toasted.error('The payment schedule in this proposal, requires a project date. Add a project date or change the payment schedule.')
        return
      }
      this.currentItem = item
    },
  }
}
</script>

<style lang="scss" scoped>
  .workflow-items,
  .no-items {
    margin-top: 38px;
  }
</style>
