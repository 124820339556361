<template>
  <div class="py-4 flex">
    <div class="mr-4 mt-1">
      <icon icon="questionnaire" />
    </div>
    <div class="flex-1">
      <div class="mb-1 flex justify-between items-center">
        <div>Questionnaire: {{ questionnaire.title }}</div>
        <context-actions
          :actions="getActions()"
        />
      </div>

      <div class="mb-2 text-xs text-grey-semi-light">
        <template v-if="questionnaire.state === 'sent'">Sent on: {{ format(questionnaire.sent_at) }}</template>
        <template v-if="questionnaire.state === 'draft'">Created on: {{ format(questionnaire.created_at) }}</template>
        <template v-if="questionnaire.state === 'answered'">Answered on: {{ format(questionnaire.answered_at) }}</template>
      </div>

      <div>
        <status-badge :status="questionnaire.state" />
      </div>
    </div>
  </div>
</template>

<script>
import { format } from 'date-fns'
import Icon from '../Icon'
import StatusBadge from './StatusBadge'
import ContextActions from '../Tasks/ContextActions'

export default {
  name: 'QuestionnaireItem',
  components: { ContextActions, StatusBadge, Icon },
  emits: ['send', 'delete', 'share'],
  props: {
    questionnaire: {
      type: Object,
      required: true
    }
  },
  methods: {
    format(date) {
      return format(date, 'MMM D, YYYY')
    },
    getActions() {
      return [
        { name: 'View', action: () => this.$router.push(`/questionnaire/preview/${this.questionnaire.id}`) },
        this.questionnaire.state !== 'answered' && { name: 'Edit', action: () => this.$router.push(`/projects/${this.questionnaire.project_id}/questionnaire/${this.questionnaire.id}`) },
        this.questionnaire.state === 'draft' && { name: 'Send', action: () => this.$emit('send', this.questionnaire) },
        this.questionnaire.state === 'sent' && { name: 'Send again', action: () => this.$emit('send', this.questionnaire) },
        { name: 'Delete', action: () => this.$emit('delete', this.questionnaire) },
        this.questionnaire.state !== 'draft' && { name: 'Share link', action: () => this.$emit('share', this.questionnaire) }
      ].filter(a => a)
    }
  }
}
</script>

<style scoped>

</style>
