<template>
  <div class="py-4 flex">
    <div class="mr-4 mt-1">
      <icon icon="proposal-alt" />
    </div>
    <div class="flex-1">
      <div class="mb-1 flex justify-between items-center">
        <div>Proposal: {{ proposal.name }}</div>
        <context-actions
          :actions="getActions()"
        />
      </div>

      <div class="mb-2 text-xs text-grey-semi-light flex justify-between">
        <div v-if="proposal.state === 'sent'">Sent on: {{ format(proposal.sent_at) }}</div>
        <div v-if="proposal.state === 'draft'">Created on: {{ format(proposal.created_at) }}</div>
        <div v-if="proposal.state === 'accepted'">Accepted on: {{ format(proposal.accepted_at) }}</div>

        <div>
          <vue-numeric
            v-model="proposal.total"
            :currency-symbol-position="(currency.sign_after_price) ? 'suffix' : 'prefix'"
            :precision="2"
            read-only
            class="small-right"
            :currency="currency.sign"
            :minus="false"
            :decimal-separator="currency.decimal_separator"
            :thousand-separator="currency.thousand_separator"
          />
        </div>
      </div>

      <div>
        <status-badge :status="proposal.state" />
      </div>
    </div>
  </div>
</template>

<script>
import StatusBadge from './StatusBadge'
import Icon from '../Icon'
import { format } from 'date-fns'
import ContextActions from '../Tasks/ContextActions'
export default {
  name: 'ProposalItem',
  components: { ContextActions, Icon, StatusBadge },
  emits: ['send', 'delete', 'share'],
  props: {
    proposal: {
      type: Object,
      required: true
    },
    currency: {
      type: Object,
      required: true
    }
  },
  methods: {
    format(date) {
      return format(date, 'MMM D, YYYY')
    },
    getActions() {
      return [
        { name: 'View', action: () => this.$router.push(`/proposals/preview/${this.proposal.id}`) },
        { name: 'Edit', action: () => this.$router.push(`/projects/${this.proposal.project_id}/proposal/${this.proposal.id}`) },
        this.proposal.state === 'draft' && { name: 'Send', action: () => this.$emit('send', this.proposal) },
        this.proposal.state === 'sent' && { name: 'Send again', action: () => this.$emit('send', this.proposal) },
        { name: 'Delete', action: () => this.$emit('delete', this.proposal) },
        this.proposal.state !== 'draft' && { name: 'Share link', action: () => this.$emit('share', this.proposal) }
      ].filter(a => a)
    }
  }
}
</script>

<style scoped>

</style>
