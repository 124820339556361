<template>
  <div class="py-4 flex">
    <div class="mr-4 mt-1">
      <icon icon="invoice-black" />
    </div>
    <div class="flex-1">
      <div class="mb-1 flex justify-between items-center">
        <div>Invoice: {{ invoice.subject }}</div>
        <context-actions
          :actions="getActions()"
        />
      </div>

      <div class="mb-2 text-xs text-grey-semi-light flex justify-between">
        <div>Due date: {{ format(invoice.valid_until) }}</div>

        <div>
          <vue-numeric
            v-model="formattedInvoiceAmount"
            :currency-symbol-position="(currency.sign_after_price) ? 'suffix' : 'prefix'"
            :precision="2"
            read-only
            class="small-right"
            :currency="currency.sign"
            :minus="false"
            :decimal-separator="currency.decimal_separator"
            :thousand-separator="currency.thousand_separator"
          />
        </div>
      </div>

      <div>
        <status-badge
          v-if="invoice.state === 'sent' && new Date(invoice.valid_until) < new Date()"
          status="late"
        />
        <status-badge
          v-else-if="invoice.total_amount < 0 || invoice.total < 0"
          status="credit"
        />
        <status-badge
          v-else
          :status="invoice.state"
        />
      </div>
    </div>
  </div>
</template>

<script>
import StatusBadge from './StatusBadge'
import Icon from '../Icon'
import { format } from 'date-fns'
import ContextActions from '../Tasks/ContextActions'

export default {
  name: 'InvoiceItem',
  components: { ContextActions, Icon, StatusBadge },
  data () {
    return {
      invoiceAmount: "0"
    }
  },
  props: {
    invoice: {
      type: Object,
      required: true
    },
    currency: {
      type: Object,
      required: true
    }
  },
  async mounted () {
    this.invoiceAmount = await this.invoice.total_amount
  },
  computed: {
    formattedInvoiceAmount: {
      get() {
        return this.invoice.total_amount;
      },
      set(value) {
        this.invoiceAmount = value;
      }
    }
  },
  methods: {
    format (date) {
      return format(date, 'MMM D, YYYY')
    },
    getActions() {
      return [
        { name: 'View', action: () => this.$router.push(`/invoices/preview/${this.invoice.id}`) },
        { name: 'Edit', action: () => this.$router.push(`/projects/${this.invoice.project_id}/invoice/${this.invoice.id}`) },
        this.invoice.state === 'draft' && { name: 'Send', action: () => this.$emit('send', this.invoice) },
        this.invoice.state === 'sent' && { name: 'Send again', action: () => this.$emit('send', this.invoice) },
        { name: 'Delete', action: () => this.$emit('delete', this.invoice) },
        this.invoice.state !== 'draft' && { name: 'Share link', action: () => this.$emit('share', this.invoice) },
        this.invoice.state === 'sent' && { name: 'Mark as paid', action: () => this.$emit('markPaid', this.invoice) }
      ].filter(a => a)
    }
  }
}
</script>

<style scoped>

</style>
