<template>
  <span
    :class="{
      'py-1 px-2 rounded-full text-xs whitespace-no-wrap': true,
      'bg-red-light text-red-medium': status === 'due',
      'bg-blue-82 text-white font-bold': status === 'today',
      'bg-blue-washed': status === 'future'
    }"
  >
    {{ dayDifference }}
  </span>
</template>

<script>
export default {
  name: 'DueBadge',
  props: {
    date: {
      type: Date,
      required: true
    }
  },
  computed: {
    diff() {
      const now = new Date()
      const diff = now.getTime() - this.date.getTime()
      return Math.floor(diff / (1000 * 60 * 60 * 24))
    },
    dayDifference() {
      const days = this.diff
      const daysText = Math.abs(days) === 1 ? 'day' : 'days'

      if (days === 0) return 'Today'
      if (days < 0) return `In ${Math.abs(days)} ${daysText}`

      return `${Math.abs(days)} ${daysText} late`
    },
    status() {
      const days = this.diff

      if (days === 0) return 'today'
      if (days < 0) return 'future'

      return 'due'
    }
  }
}
</script>

<style scoped>

</style>
