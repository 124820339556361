var render = function render(){var _vm=this,_c=_vm._self._c;return _c('span',{class:{
    'py-1 px-2 rounded-full text-xs whitespace-no-wrap': true,
    'bg-red-light text-red-medium': _vm.color === 'red',
    'bg-blue-washed': _vm.color === 'blue',
    'bg-blue-82 text-white font-bold': _vm.color === 'blue-dark',
    'bg-green-light text-green-medium': _vm.color === 'green',
  }},[_vm._t("default")],2)
}
var staticRenderFns = []

export { render, staticRenderFns }