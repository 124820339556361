<template>
  <div>
    <h3>Files</h3>

    <loader v-if="loading" />

    <div v-if="!loading && files.length === 0">
      There are no files in this project.
    </div>

    <template
      v-if="!loading && files.length > 0"
    >
      <div
        v-for="file in files"
        :key="`${file.type}.${file.id}`"
        class="file border-b border-grey-lighter"
      >
        <a
          style="cursor:pointer"
          @click="goToFileLocation(file, 'questionnaire')"
        >
          <questionnaire-item
            v-if="file.type === 'questionnaire'"
            :questionnaire="file"
            @send="$emit('send', file)"
            @delete="$emit('delete', file)"
            @share="$emit('share', file)"
          />
        </a>
        <a
          style="cursor:pointer"
          @click="goToFileLocation(file, 'proposal')"
        >
          <proposal-item
            v-if="file.type === 'proposal'"
            :proposal="file"
            :currency="currencies.find(x => x.id === file.currency_id)"
            @send="$emit('send', file)"
            @delete="$emit('delete', file)"
            @share="$emit('share', file)"
          />
        </a>
        <a
          style="cursor:pointer"
          @click="goToFileLocation(file, 'invoice')"
        >
          <invoice-item
            v-if="file.type === 'invoice'"
            :invoice="file"
            :currency="currencies.find(x => x.id === file.currency_id)"
            @send="$emit('send', file)"
            @delete="$emit('delete', file)"
            @share="$emit('share', file)"
            @markPaid="$emit('markInvoicePaid', file)"
          />
        </a>
      </div>
    </template>
  </div>
</template>

<script>
import { getFiles } from '../api/Files'
import Loader from './Loader'
import QuestionnaireItem from './Files/QuestionnaireItem'
import ProposalItem from './Files/ProposalItem'
import InvoiceItem from './Files/InvoiceItem'

export default {
  name: 'ProjectFilesOverview',
  components: { InvoiceItem, ProposalItem, QuestionnaireItem, Loader },
  emits: ['send', 'delete', 'share', 'markInvoicePaid'],
  props: {
    project: {
      type: Object,
      required: true
    },
    currencies: {
      type: Array,
      required: true
    },
  },
  data() {
    return {
      loading: false,
      files: []
    }
  },
  mounted() {
    this.fetchFiles()
  },
  methods: {
    goToFileLocation(file, type) {
      switch (type) {
        case 'questionnaire':
          if (file.state === 'answered') {
            this.$router.push(`/questionnaire/preview/${file.id}`)
          } else {
            this.$router.push(`/projects/${this.project.id}/questionnaire/${file.id}`)
          }
          break
        case 'proposal':
          if (this.project.status === 'draft') {
            this.$router.push(`/projects/${this.project.id}/proposal/${file.id}`)
          } else {
            this.$router.push(`/proposals/preview/${file.id}`)
          }

          break
        case 'invoice':
          this.$router.push(`/projects/${this.project.id}/invoice/${file.id}`)
          break
      }
    },
    async fetchFiles() {
      this.loading = true
      const res = this.handleErrors(await getFiles(this.project.id))

      if (res) {
        this.files = res.data
      }

      this.loading = false
    }
  }
}
</script>

<style lang="scss" scoped>
  .file:last-of-type {
    border-bottom: none;
  }
</style>
