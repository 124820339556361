<template>
  <button
    v-if="action.automated"
    class="bg-grey-f9 py-2 px-4 rounded inline-flex items-center action-item action-item--automated"
    :class="{
      'text-grey-darker': action.status !== 'completed',
      'text-grey-semi-light line-through': action.status === 'completed'
    }"
    :disabled="action.status === 'completed'"
    @click="$emit('click')"
  >
    <icon
      class="mr-2"
      icon="refresh"
    />
    <span
      class="action-name"
    >
      {{ action.name }}
    </span>
  </button>
  <button
    v-else
    class="py-2 px-4 rounded action-item action-name"
    :class="{
      'bg-green text-white font-bold': action.status !== 'completed',
      'bg-grey-f9 grey-semi-light line-through': action.status === 'completed'
    }"
    :disabled="action.status === 'completed'"
    @click="$emit('click')"
  >
    {{ action.name }}
  </button>
</template>

<script>
import Icon from '../Icon'

export default {
  name: 'ActionItem',
  components: { Icon },
  emits: ['click'],
  props: {
    action: {
      type: Object,
      required: true
    }
  }
}
</script>

<style scoped>
  .action-name {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .action-item {
    max-width: 100%;
    min-width: 0;
  }

  .action-item.action-item--automated {
    max-width: calc(100% - 44px);
  }
</style>
